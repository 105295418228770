body.ram{
    background-color: brown!important;
    /* background: url(./sky-ram.jpg) no-repeat center center; */
    background-size: cover;
    background-position-y: 0%; 
} 

.btn-access-press{
    -webkit-box-shadow: 0px 5px 21px 2px rgba(138,121,138,1);
    -moz-box-shadow: 0px 5px 21px 2px rgba(138,121,138,1);
    box-shadow: 0px 5px 21px 2px rgba(138,121,138,1);
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #C40A2F;
    border-color: #C40A2F;
}