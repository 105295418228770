h1.cate-title {
  margin-top: 0px; }

.products.products-grid .product-items .col-sm-4:nth-child(3n+1) {
  clear: none; }

.site-footer .footer-column {
  padding-bottom: 0px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-70 {
  margin-top: 70px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-90 {
  margin-top: 90px; }

.margin-top-100 {
  margin-top: 100px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-70 {
  margin-bottom: 70px; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.margin-bottom-90 {
  margin-bottom: 90px; }

.margin-bottom-100 {
  margin-bottom: 100px; }

.block-form-login .form-control, .block-form-login input {
  max-width: 100%; }

.flat-alert {
  padding: 10px;
  border-radius: 0px; }

.flat, .flat .modal-content {
  border-radius: 0px; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  color: #000; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  color: #000; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  color: #000; }

.modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.modal.fade .modal-dialog {
  transform: translate(0, -100%); }

.modal.in .modal-dialog {
  transform: translate(0, 0); }

.modal-backdrop.in {
  opacity: 0.7; }

.modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%); }

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.loader-hidden {
  visibility: hidden; }

.loader-active {
  visibility: visible; }

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4); }

.site-header .setting .switcher li {
  cursor: pointer; }

.logo-footer img {
  max-width: 200px; }

.footer-opt-2 {
  padding-top: 10px; }

.site-footer .footer-column .address {
  margin-bottom: 20px; }

.site-footer .copyright {
  padding-bottom: 10px; }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px; }

@media screen and (max-width: 767px) {
  footer {
    height: 383px; } }

.product-item:hover, product-item-opt-2:hover {
  cursor: pointer; }

.header-nav .setting {
  right: 52px; }

.header-nav .block-search {
  right: 0px; }

@font-face {
  font-family: "Century-Gothic";
  src: url("./assets/styles/fonts/Century_Gothic.ttf"); }

@font-face {
  font-family: "Century-Gothic";
  font-weight: bold;
  src: url("./assets/styles/fonts/Century_Gothic_Bold.ttf"); }

html {
  position: relative;
  min-height: 100%; }

body {
  margin-bottom: 180px;
  max-width: none; }

.product-item-opt-2 .product-item-img img {
  margin: 15px auto !important;
  width: 150px;
  height: 200px; }

.product-item-opt-2 {
  background-color: transparent; }

.product-item-opt-2 .product-item-photo {
  padding: 0; }

@media screen and (max-width: 767px) {
  body {
    margin-bottom: 383px; } }

img.logo {
  max-width: 200px; }

@media screen and (max-width: 991px) {
  .logo img {
    max-width: 200px; } }

@media screen and (min-width: 600px) and (max-width: 991px) {
  .header-opt-2 .header-content .nav-left {
    text-align: left;
    display: inline-block; }
  .header-opt-2 .header-content .nav-mind {
    float: right; } }

@media (max-width: 479px) {
  .products.products-grid .product-items.row {
    margin: 0px !important;
    text-align: center;
    justify-content: space-around;
    flex: 1;
    flex-direction: row; }
  .products.products-grid .product-items .product-item {
    width: 100%;
    max-width: 165px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 5px;
    clear: none !important; }
  h1.cate-title {
    font-size: 20px; } }

body,
* {
  font-family: "Century-Gothic"; }

.loader-inner > div {
  background-color: #622181; }

.active,
.ui-menu li.active,
.ui-menu li a.active {
  color: #622181; }

.product-item-opt-2 .product-item-actions .btn.active,
.product-item-opt-2 .product-item-actions .btn:hover {
  color: #622181;
  background-color: #fff; }

.ppc-section img {
  height: 110px !important;
  margin: auto; }

.ppc-section h3 {
  text-align: center; }

.ppc-section p {
  text-align: justify; }

.site-header .setting .switcher-language .switcher-active {
  outline: #622181 solid 2px; }

.header-opt-2 .header-content .block-search .form-search .form-control {
  padding-left: 15px; }

#mentions h2,
#mentions #email,
#mentions span {
  color: #622181 !important; }

.header-top,
.header-opt-2 .header-top,
.box-header-nav .dropdown-toggle,
.index-opt-2 .back-to-top .fa,
.index-opt-2 .back-to-top:hover,
.header-opt-2 .header-content .block-search .form-search .btn-search,
.header-opt-2 .header-content .block-search .form-search .btn-search:hover,
.header-opt-2 .header-nav .setting .dropdown-toggle,
.header-opt-2 .header-nav .block-search .block-title,
.header-opt-2 .header-nav .block-search .form-search .btn-search,
.site-header .setting .switcher-language .switcher-active {
  background-color: #622181; }

.category-view .owl-nav .owl-next:hover,
.category-view .owl-nav .owl-prev:hover {
  background-color: #622181;
  color: "#FFF"; }

.btn-inline {
  background-color: #622181;
  border-color: #622181;
  color: "#FFF"; }

.block-forgot-pass a {
  color: #622181; }

.index-opt-2 .back-to-top,
.index-opt-2 .back-to-top .fa,
.header-opt-2 .header-content .block-search .form-search .btn-search,
.header-opt-2 .header-nav .block-search .form-search .btn-search,
.index-opt-2 .back-to-top .fa,
.index-opt-2 .back-to-top:hover,
.index-opt-2 .back-to-top:hover {
  border-color: #622181; }

a:focus,
a:hover,
.active,
.ui-menu li.active,
.ui-menu li a.active,
.site-footer .footer-column .links a:hover {
  color: #622181; }
